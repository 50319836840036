import {
  AfterViewInit,
  Component,
  DoCheck,
  NgZone,
  OnInit
} from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import * as moment from 'moment';
import { SwUpdate } from '@angular/service-worker';
import {
  filter,
  map,
  mergeMap,
  retry, takeUntil,
  tap
} from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';
import { Title } from '@angular/platform-browser';
import { CompleteProfileDialogService } from '@rhbnb-nx-ws/ui-share-components';
import { Subscription, of } from 'rxjs';

import { LoadIconsService } from '../shared/services/load-icons.service';
import { WithUnsubscribe } from '../shared/classes/with-unsubscribe';
import { SecurityService } from '../shared/services/security.service';
import { CoreStoreService } from './store/core-store.service';
import { LanguageService } from '../shared/services/language.service';
import { TagMetadataService } from '../shared/services/tag-metadata.service';
import { UserApiService } from '../shared/services/user-api.service';
import { NavigationEnd, Router } from '@angular/router';
import { LayoutStoreService } from '../layout/store/layout-store.service';

@Component({
  selector: 'rhbnb-root',
  template: ` <router-outlet (activate)="onActivate($event)"></router-outlet> `,
  styleUrls: ['./app.component.sass'],
})
export class AppComponent
  extends WithUnsubscribe()
  implements DoCheck, OnInit, AfterViewInit {
  count = 0;

  constructor(
    private loadIconService: LoadIconsService,
    private logger: NGXLogger,
    private securityService: SecurityService,
    private translocoService: TranslocoService,
    private titleService: Title,
    private swUpdate: SwUpdate,
    private coreStoreService: CoreStoreService,
    private languageService: LanguageService,
    private tagMetadataService: TagMetadataService,
    private zone: NgZone,
    private completeProfileDialogService: CompleteProfileDialogService,
    private userApiService: UserApiService,
    private router: Router,
    private layoutStoreService: LayoutStoreService,
  ) {
    super();
  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
    this.zone.runOutsideAngular(() => {
      this.loadIconService.loadAll();
      this.securityService.startIdentityCheck(this.unsubscribe$);

      this.zone.run(() => this.handleUpdate());

      this.zone.run(() => {
        this.handleLangChange();

        this.tagMetadataService.listenForTagsChanges();
        this.tagMetadataService.setDefaultTags();
      });
    });

    this.handleProfileComplete();
    this.handleRoutingChanges();
  }

  handleRoutingChanges() {
    this.router.events
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((e) => {
          if (e instanceof NavigationEnd) {
            const url = e.urlAfterRedirects;
            const urlSegments = url.split('/').filter((segment) => segment);

            // On routes / or /[slug], remove destination query
            if (urlSegments.length <= 1) {
              const url = new URL(e.urlAfterRedirects, window.location.origin);
              const params = new URLSearchParams(url.search);

              const PARAMS__TO_REMOVE = [
                'destination',
                'outingDestination',
                'selectedProvince',
                'outingSelectedProvince',
              ];

              for (const param of PARAMS__TO_REMOVE) {
                params.delete(param);

                this.layoutStoreService.setFilterDestination(null);
                this.layoutStoreService.setFilterOutingDestination(null);
                this.layoutStoreService.setFilterSelectedProvince(null);
                this.layoutStoreService.setFilterOutingSelectedProvince(null);
              }

              window.history.replaceState({}, '', `${url.pathname}?${params}`);
            }
          }
        })
      )
      .subscribe();
  }

  ngDoCheck(): void {
    this.logger.debug(`DoCheck On Core: ${++this.count}`);
  }

  handleProfileComplete() {
    const sub: Subscription = this.coreStoreService
      .getUser()
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((u) => !!u),
        mergeMap((u) => {
          return !u.mobile
            ? this.completeProfileDialogService.open(
              'core.complete_profile_title',
              u
            )
            : of(false);
        }),
        mergeMap((data) => this.userApiService.patchMe({ ...data })),
        retry(10),
        tap((res) => {
          const user = res.data;

          this.securityService.setProfile(user);
          this.coreStoreService.setUser(user);

          sub?.unsubscribe();
        })
      )
      .subscribe();
  }

  /**
   * Handle SW Update
   */
  handleUpdate() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates
        .pipe(
          filter((e) => e.type === 'VERSION_READY'),
          takeUntil(this.unsubscribe$),
          map(() => this.coreStoreService.setUpdateAvailable(true))
        )
        .subscribe();
    }
  }

  handleLangChange() {
    this.translocoService
      .selectTranslate('core.title')
      .pipe(
        tap((t) => this.titleService.setTitle(t)),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();

    this.coreStoreService
      .getCurrentLang()
      .pipe(
        tap((lang) => this.translocoService.setActiveLang(lang)),
        tap((lang) => this.languageService.storeLang(lang)),
        tap((lang) => moment.locale(lang)),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  onActivate(e: {}) {
    window.scroll(0, 0);
  }
}
