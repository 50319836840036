export const isSameDay = (date1: Date, date2: Date) => {
  return date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate();
}

export const buildDateSafety = (date: string, lang = 'en-US') => {
  const dateParts = date.split('-');

  const year = parseInt(dateParts[0], 10);
  const month = parseInt(dateParts[1], 10) - 1;
  const day = parseInt(dateParts[2], 10);

  return new Date(year, month, day);
}

export const compareDate = (d1: string, d2: string) => {
  if (!d1 || !d2) {
    return null;
  }

  const date1 = buildDateSafety(d1);
  const date2 = buildDateSafety(d2);

  if (isSameDay(date1, date2)) {
    return 0;
  }

  return date1 > date2 ? 1 : -1;
}

export const datePartsToFormat = (year: number, month: number, day: number) => {
  const pDay = String(day).padStart(2, '0');
  const pMonth = String(month).padStart(2, '0');

  return `${year}-${pMonth}-${pDay}`;
}

export const formatToDateParts = (date: string) => {
  const parts = date.split('-');
  return [Number.parseInt(parts[0]), Number.parseInt(parts[1]), Number.parseInt(parts[2])];
}

export const formatDate = (date: Date) => {
  const year = date.getFullYear();
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export const getNextDate = (day: number, month: number, year: number) => {
  const daysInMonth = new Date(year, month, 0).getDate();

  let nextDay = day + 1;
  let nextMonth = month;
  let nextYear = year;

  if (day === daysInMonth) {
    nextDay = 1;
    nextMonth = month + 1;

    if (month === 12) {
      nextMonth = 1;
      nextYear = year + 1;
    }
  }

  return datePartsToFormat(nextYear, nextMonth, nextDay);
}

export const dateRangeGenerator = function* (from: string, to: string) {
  const [startYear, startMonth, startDay] = formatToDateParts(from);
  const [endYear, endMonth, endDay] = formatToDateParts(to);

  let currentDay = startDay;
  let currentMonth = startMonth;
  let currentYear = startYear;

  while (currentYear < endYear || (currentYear === endYear && currentMonth < endMonth) || (currentYear === endYear && currentMonth === endMonth && currentDay <= endDay)) {
    yield datePartsToFormat(currentYear, currentMonth, currentDay);
    const nextDate = getNextDate(currentDay, currentMonth, currentYear);

    const [y, m, d] = formatToDateParts(nextDate);
    currentDay = d;
    currentMonth = m;
    currentYear = y;
  }
}

export const isLockByMinNights = (date: string, minNightsDates: string[]) => {
  return (minNightsDates || []).includes(date);
}

export const byMinDate = (date: string, minDate: string) => {
  const compare = compareDate(date, minDate);
  return minDate && compare === -1 || compare === 0;
};

export const isLock = (date: string, minDate: string, disabledDates: string[], nextDisabledDate: string) => {
  const byDisabled = () => {
    return (disabledDates || []).includes(date);
  };

  const byNextDisabledDate = () => {
    return nextDisabledDate && (compareDate(date, nextDisabledDate) === 1
      || compareDate(date, nextDisabledDate) === 0);
  };

  return byMinDate(date, minDate) || byDisabled() || byNextDisabledDate();
}

export const isOnlyOut = (year: number, month: number, day: number, disabledDates: string[]) => {
  const next = getNextDate(day, month, year);
  return disabledDates.includes(next);
}

export const getDateLimits = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const firstDay = `${year}-${month.toString().padStart(2, '0')}-01`;
  const lastDay = new Date(year, month, 0).toISOString().split('T')[0];

  return [firstDay, lastDay];
}
