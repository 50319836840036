<div class="row" #dateControlsWrapper (click)="togglePicker()">
  <mat-form-field class="col-6">
    <mat-label>{{ labels[0] }}</mat-label>
    <input matInput [value]="(range[0] | isMomentValid) ? (range[0] | amDateFormat:'MMM D'): ''" [readonly]="true" />
  </mat-form-field>

  <mat-form-field class="col-6">
    <mat-label>{{ labels[1] }}</mat-label>
    <input matInput [value]="(range[1] | isMomentValid) ? (range[1] | amDateFormat:'MMM D'): ''" [readonly]="true" />
  </mat-form-field>
</div>

<div class="inline-picker d-md-none d-lg-none d-xl-none d-xxl-none">
  <rhbnb-range-picker [minNights]="minNights" [loadDisabledDatesFn]="loadDisabledDatesFn" [lang]="lang" [range]="[
  (range[0] | isMomentValid)
    ? (range[0] | amDateFormat:'YYYY-MM-DD')
    : undefined,
  (range[1] | isMomentValid)
    ? (range[1] | amDateFormat:'YYYY-MM-DD')
    : undefined]" (rangeChange)="onRangeChange($event)" [minDate]="now | dateToString"
    [singleCalendar]="true"></rhbnb-range-picker>
</div>

<section #datePickerWrapper class="date-picker" *ngIf="showDatePicker" [ngStyle]="dropdownStyle">
  <rhbnb-range-picker [minNights]="minNights" [loadDisabledDatesFn]="loadDisabledDatesFn" [lang]="lang" [range]="[
  (range[0] | isMomentValid)
    ? (range[0] | amDateFormat:'YYYY-MM-DD')
    : undefined,
  (range[1] | isMomentValid)
    ? (range[1] | amDateFormat:'YYYY-MM-DD')
    : undefined]" [minDate]="now | dateToString" (rangeChange)="onRangeChange($event)"></rhbnb-range-picker>
</section>
