import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { DateRangeModel, Destination } from '@rhbnb-nx-ws/domain';
import { map, takeUntil } from 'rxjs/operators';

import { TopFilterService } from '../top-filter.service';
import { FilterChangesModel, FilterDataChange } from '../filter-changes.model';
import { WithUnsubscribe } from '@rhbnb-nx-ws/utils';
import * as moment from 'moment';

@Component({
  selector: 'rhbnb-house-filter',
  templateUrl: './house-filter.component.html',
  styleUrls: ['./house-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HouseFilterComponent extends WithUnsubscribe() implements OnInit {

  dest$: Observable<any>;

  @Input() destinations$: Observable<Destination[]>;
  @Input() destination$: Observable<number | string>;
  @Input() dateRange$: Observable<DateRangeModel>;
  @Input() room$: Observable<number>;
  @Input() guest$: Observable<number>;
  @Input() alternateColor = false;
  @Input() lang: string;

  @Output() modelChange = new EventEmitter<FilterDataChange>();

  dateLabel$: Observable<string>;

  constructor(
    public topFilterService: TopFilterService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.dest$ = this.destination$
      .pipe(
        takeUntil(this.unsubscribe$),
        map((d) => {
          return !isNaN(Number(d)) ? d : ({ province: d });
        })
      );
  }

  onDestinationChange(destination: any): void {
    if (!destination?.id) {
      // Users can select the whole province
      // in this case, destination id is undefined
      this.modelChange.emit({
        type: FilterChangesModel.DESTINATION,
        data: destination?.province
      });
    } else {
      this.modelChange.emit({
        type: FilterChangesModel.DESTINATION,
        data: destination?.id
      });
    }
  }

  onRangeChange([from, to]: [string, string]) {
    this.modelChange.emit({
      type: FilterChangesModel.DATE_RANGE,
      data: {
        startDate: from ? moment(from) : undefined,
        endDate: to ? moment(to) : undefined,
      }
    });
  }

  onRoomChange(event): void {
    this.modelChange.emit({
      type: FilterChangesModel.ROOM,
      data: event
    });
  }

  onGuestChange(event): void {
    this.modelChange.emit({
      type: FilterChangesModel.GUESTS,
      data: event
    });
  }
}
