import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isMomentValid'
})
export class IsMomentValidPipe implements PipeTransform {

  constructor(
  ) {}

  transform(value: any): unknown {
    return value?.isValid();
  }
}
