import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { WithUnsubscribe } from '@rhbnb-nx-ws/utils';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'rhbnb-date-picker-input-form',
  templateUrl: './input-form.component.html',
  styleUrls: ['./input-form.component.scss'],
})
export class InputFormComponent extends WithUnsubscribe() implements OnInit {
  @Input() lang: string;
  @Input() minNights: number;
  @Input() labels: [string, string];
  _range: [any, any];
  @Input() set range(value: [any, any]) {
    this._range = value;
  }
  get range() {
    return this._range;
  }
  @Input() loadDisabledDatesFn: (from: string, end: string) => Promise<string[]>;

  @Output() rangeChange = new EventEmitter<[string, string]>();

  now = new Date();
  showDatePicker = false;
  showInlineDatePicker = false;
  dropdownStyle = {};
  private clickOutsideListener: () => void;

  @ViewChild('dateControlsWrapper') dateTriggerElement: ElementRef;
  @ViewChild('datePickerWrapper') datePickerWrapper: ElementRef;

  constructor(
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    private bpo: BreakpointObserver,
  ) {
    super();
  }

  ngOnInit(): void {
    this.bpo.observe('(min-width: 768px)')
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(500),
        tap(resp => {
          this.showInlineDatePicker = !resp.matches;
        })
      )
      .subscribe();
  }

  togglePicker() {
    if (this.showInlineDatePicker) {
      return;
    }

    this.showDatePicker = !this.showDatePicker;

    setTimeout(() => {
      if (this.showDatePicker) {
        const triggerElement = this.dateTriggerElement.nativeElement;
        const top = triggerElement.offsetTop + triggerElement.offsetHeight;
        const left = triggerElement.offsetLeft + (triggerElement.offsetWidth / 2) - (this.datePickerWrapper.nativeElement.offsetWidth / 2);

        this.dropdownStyle = {
          top: `${top}px`,
          left: `${left}px`,
          visibility: 'visible',
        };

        this.cdr.detectChanges();
        this.addClickOutsideListener();
      } else {
        this.removeClickOutsideListener();
      }
    });
  }

  addClickOutsideListener() {
    this.removeClickOutsideListener();
    this.renderer.listen('document', 'click', this.onClickOutside.bind(this));
  }

  removeClickOutsideListener() {
    if (this.clickOutsideListener) {
      this.clickOutsideListener();
      this.clickOutsideListener = null;
    }
  }

  onClickOutside(event: Event) {
    if (!this.datePickerWrapper?.nativeElement.contains(event.target) && !this.dateTriggerElement?.nativeElement.contains(event.target)) {
      this.showDatePicker = false;
      this.cdr.detectChanges();
      this.removeClickOutsideListener();
    }
  }

  onRangeChange(range: [string, string]) {
    this.rangeChange.emit(range);
  }
}
