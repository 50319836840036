import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isInRange'
})

export class IsInRangePipe implements PipeTransform {
  transform(target: string, from: string, to: string): any {
    const date = new Date(target);
    const startDate = new Date(from);
    const endDate = new Date(to);

    return date > startDate && date < endDate;
  }
}
