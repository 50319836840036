import { Pipe, PipeTransform } from '@angular/core';
import { getNextDate } from './functions';

@Pipe({
  name: 'getNextDate'
})

export class GetNextDatePipe implements PipeTransform {
  transform(day?: number, year?: number, month?: number): any {
    return getNextDate(day, month, year);
  }
}
