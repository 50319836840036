<div class="row m-0">
  <div class="col-12 col-md-3">
    <ng-select appearance="outline" [placeholder]="'filter.destiny_label' | transloco" [items]="destinations$ | async"
      [ngModel]="dest$ | async" [compareWith]="topFilterService.compareDestination" [clearable]="false"
      (change)="onDestinationChange($event)" [selectableGroup]="true" bindValue="id" bindLabel="name"
      groupBy="province">
    </ng-select>
  </div>

  <div class="col-12 col-md-5" *ngIf="dateRange$ | async as range">
    <rhbnb-date-picker-input-form
      [lang]="lang"
      [range]="[range?.startDate, range?.endDate]"
      [labels]="[
        'filter.date_from_label' | transloco,
        'filter.date_to_label' | transloco,
      ]"
      (rangeChange)="onRangeChange($event)"
    ></rhbnb-date-picker-input-form>
  </div>

  <div class="col-12 col-md-2">
    <rhbnb-auto-numbered-mat-select [label]="'filter.rooms_label' | transloco" [value]="room$ | async"
      (valueChange)="onRoomChange($event)">
    </rhbnb-auto-numbered-mat-select>
  </div>

  <div class="col-12 col-md-2">
    <rhbnb-auto-numbered-mat-select [label]="'filter.guests_label' | transloco" [value]="guest$ | async"
      (valueChange)="onGuestChange($event)">
    </rhbnb-auto-numbered-mat-select>
  </div>
</div>
