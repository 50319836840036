import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { byMinDate, compareDate, datePartsToFormat, isLock, isLockByMinNights, isOnlyOut } from '../functions';
import { trans } from '../i18n';

type TOOLTIP_POSITION = 'top' | 'top-left' | 'top-right' | 'bottom' | 'bottom-left' | 'bottom-right' | 'left' | 'right';

@Component({
  selector: 'rhbnb-single-calendar',
  templateUrl: './single-calendar.component.html',
  styleUrls: ['./single-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SingleCalendarComponent implements OnInit {
  _date: string;
  @Input() set date(value: string) {
    this._date = value;
    this.init();
  }
  get date() {
    return this._date;
  }
  @Input() place: 'LEFT' | 'RIGHT';
  @Input() firstSelected: string;
  @Input() lastSelected: string;
  @Input() singleSelected: string;
  @Input() hoverDate: string;
  @Input() minDate: string;
  @Input() maxDate: string;
  @Input() disabledDates: string[] = [];
  @Input() nextDisabledDate: string;
  @Input() minNights: number;
  @Input() minNightsDates: string[] = [];
  @Input() singleCalendar = false;
  @Input() lang: string;
  @Input() selectRange: boolean;
  @Input() dayItemSize: number;
  @Input() loading: boolean;

  @Output() nextChange = new EventEmitter<void>();
  @Output() prevChange = new EventEmitter<void>();
  @Output() firstSelectedChange = new EventEmitter<string>();
  @Output() lastSelectedChange = new EventEmitter<string>();
  @Output() singleSelectedChange = new EventEmitter<string>();
  @Output() hoverDateChange = new EventEmitter<string>();

  myCalendarYear: number;
  myCalendarMonth: number;
  daysOfWeek: number[] = [0, 1, 2, 3, 4, 5, 6];
  weeks: Array<Array<{ day: number } | null>> = [];


  constructor(
  ) { }

  ngOnInit(): void {
  }

  init() {
    const date = new Date(this.date);

    this.myCalendarYear = date.getFullYear();
    this.myCalendarMonth = date.getMonth();
    this.generateCalendar(this.myCalendarYear, this.myCalendarMonth);
  }

  generateCalendar(year: number, month: number): void {
    this.weeks = [];
    const firstDay = (new Date(year, month, 1).getDay() + 6) % 7;
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    let day = 1;
    for (let i = 0; i < 6; i++) {
      const week: Array<{ day: number } | null> = [];
      for (let j = 0; j < 7; j++) {
        if ((i === 0 && j < firstDay) || day > daysInMonth) {
          week.push(null);
        } else {
          week.push({ day });
          day++;
        }
      }
      this.weeks.push(week);
    }
  }

  onNext() {
    this.nextChange.emit();
  }

  onPrev() {
    this.prevChange.emit();
  }

  onDateHover(day?: number) {
    if (day === undefined || day === null) {
      return;
    }

    this.hoverDateChange.emit(this.generateDateByDay(day));
  }

  onSelect(day?: number, el?: HTMLElement) {
    if (this.loading) {
      return;
    }

    if (day === undefined || day === null) {
      return;
    }

    const date = this.generateDateByDay(day);

    if (this.selectRange && this.isLockByMinNights(date)) {
      this.addTooltip(el, trans(this.lang, 'min-nights', [this.minNights]));
      return;
    }

    if (this.firstSelected && !this.lastSelected && byMinDate(date, this.firstSelected)) {
      this.firstSelectedChange.emit(date);
      return;
    }

    if (this.isLock(date)) {
      return;
    }

    if (!this.selectRange) {
      this.singleSelectedChange.emit(date);
      return;
    }

    if (this.firstSelected && this.lastSelected) {
      this.lastSelectedChange.emit(undefined);

      if (this.isOnlyOut(this.myCalendarYear, this.myCalendarMonth + 1, day)) {
        this.addTooltip(el, trans(this.lang, 'just-checkout'));
        this.firstSelectedChange.emit(undefined);
        return;
      }

      this.firstSelectedChange.emit(date);
      return;
    }

    if (!this.firstSelected && this.isOnlyOut(this.myCalendarYear, this.myCalendarMonth + 1, day)) {
      this.addTooltip(el, trans(this.lang, 'just-checkout'));
      return;
    }

    if (!this.firstSelected) {
      this.firstSelectedChange.emit(date);
      return;
    }

    if (date === this.firstSelected) {
      this.firstSelectedChange.emit(undefined);
    }

    const compared = compareDate(this.firstSelected, date);

    if (compared === 0 || compared === 1) {
      return;
    }

    this.lastSelectedChange.emit(date);
  }

  addTooltip(target: HTMLElement, msg: string, position: TOOLTIP_POSITION = 'top') {
    if (target) {
      target.setAttribute('aria-label', msg);
      target.setAttribute('data-microtip-position', position);
      target.setAttribute('role', 'tooltip');
    }
  }

  removeTooltip(target: any) {
    if (target && target instanceof HTMLElement) {
      target.removeAttribute('aria-label');
      target.removeAttribute('data-microtip-position');
      target.removeAttribute('role');
    }
  }

  private generateDateByDay(day: number) {
    return datePartsToFormat(this.myCalendarYear, this.myCalendarMonth + 1, day);
  }

  private isLockByMinNights(date: string) {
    return isLockByMinNights(date, this.minNightsDates);
  }

  private isLock(date: string) {
    return isLock(date, this.minDate, this.disabledDates, this.nextDisabledDate);
  }

  private isOnlyOut(year: number, month: number, day: number) {
    return isOnlyOut(year, month, day, this.disabledDates);
  }

  trackByFn(index: number, day: any) {
    return day;
  }
}
