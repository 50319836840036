import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WithUnsubscribe } from '@rhbnb-nx-ws/utils';

@Component({
  selector: 'rhbnb-date-picker-inline',
  templateUrl: './inline.component.html',
  styleUrls: ['./inline.component.scss'],
})
export class InlineComponent extends WithUnsubscribe() implements OnInit {
  @Input() lang: string;
  @Input() range: [any, any];
  @Input() disabledDates: string[];
  @Input() autoSize = false;
  @Input() autoChangeCalendar = false;
  @Input() minNights = 1;
  @Input() singleCalendar = false;
  @Input() loadDisabledDatesFn: (from: string, end: string) => Promise<string[]>;

  @Output() rangeChange = new EventEmitter<[string, string]>();
  @Output() viewDateChange = new EventEmitter<[string, string]>();

  now = new Date();

  constructor(
  ) {
    super();
  }

  ngOnInit(): void {
  }

  onRangeChange(range: [string, string]) {
    this.rangeChange.emit(range);
  }

  onViewDateChange(range: [string, string]) {
    this.viewDateChange.emit(range);
  }
}
