import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RangePickerComponent } from './range-picker.component';
import { SingleCalendarComponent } from './single-calendar/single-calendar.component';
import { DateToStringPipe } from './format-date.pipe';
import { BuildDateStringPipe } from './build-date-string.pipe';
import { IsSelectedDatePipe } from './is-selected-date.pipe';
import { IsInRangePipe } from './is-in-range.pipe';
import { CompareDatePipe } from './compare-date.pipe';
import { IsDateEqualPipe } from './is-date-equal.pipe';
import { IsDateGreaterThanPipe } from './is-date-greater.pipe';
import { IsDateLessThanPipe } from './is-date-less.pipe';
import { IsDateGreaterThanOrEqualPipe } from './is-date-greater-or-equal.pipe';
import { IsDateLessThanOrEqualPipe } from './is-date-less-or-equal.pipe';
import { IsInArrayPipe } from './is-in-array.pipe';
import { GetNextDatePipe } from './get-next-date.pipe';
import { TransPipe } from './trans.pipe';
import { SharedPipesModule } from '@rhbnb-nx-ws/shared-pipes';
import { MapEmptyRangePipe } from './map-empty-range.pipe';
import { InputFormComponent } from './input-form/input-form.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MomentModule } from 'ngx-moment';
import { InlineComponent } from './inline/inline.component';
import { MatButtonModule } from '@angular/material/button';

const COMPONENTS = [
  RangePickerComponent,
  SingleCalendarComponent,
  DateToStringPipe,
  BuildDateStringPipe,
  IsSelectedDatePipe,
  IsInRangePipe,
  CompareDatePipe,
  IsInArrayPipe,
  IsDateEqualPipe,
  IsDateGreaterThanPipe,
  IsDateLessThanPipe,
  IsDateGreaterThanOrEqualPipe,
  IsDateLessThanOrEqualPipe,
  GetNextDatePipe,
  TransPipe,
  InputFormComponent,
  MapEmptyRangePipe,
  InlineComponent,
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    SharedPipesModule,
    MomentModule,
    MatButtonModule,
  ],
  exports: [...COMPONENTS],
})
export class RangePickerModule {}
