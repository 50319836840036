import { Pipe, PipeTransform } from '@angular/core';
import { trans } from './i18n';

@Pipe({
  name: 'trans'
})

export class TransPipe implements PipeTransform {
  transform(msg: string, lang:string, ...args: (string|number)[]): any {
    return trans(lang, msg, args);
  }
}
