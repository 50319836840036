import { Pipe, PipeTransform } from '@angular/core';
import { compareDate } from './functions';

@Pipe({
  name: 'compareDate'
})

export class CompareDatePipe implements PipeTransform {
  transform(d1: string, d2: string): any {
    return compareDate(d1, d2);
  }
}
