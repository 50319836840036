const es = {
  'month.0': 'enero',
  'month.1': 'febrero',
  'month.2': 'marzo',
  'month.3': 'abril',
  'month.4': 'mayo',
  'month.5': 'junio',
  'month.6': 'julio',
  'month.7': 'agosto',
  'month.8': 'septiembre',
  'month.9': 'octubre',
  'month.10': 'noviembre',
  'month.11': 'diciembre',
  'week.0': 'l',
  'week.1': 'm',
  'week.2': 'x',
  'week.3': 'j',
  'week.4': 'v',
  'week.5': 's',
  'week.6': 'd',
  'just-checkout': 'Solo salida',
  'min-nights': '{0}-noche(s) mínimo',
  'remove-dates': 'Borrar fechas',
}

const en = {
  'month.0': 'january',
  'month.1': 'february',
  'month.2': 'march',
  'month.3': 'april',
  'month.4': 'may',
  'month.5': 'june',
  'month.6': 'july',
  'month.7': 'august',
  'month.8': 'september',
  'month.9': 'october',
  'month.10': 'november',
  'month.11': 'december',
  'week.0': 'M',
  'week.1': 'T',
  'week.2': 'W',
  'week.3': 'T',
  'week.4': 'F',
  'week.5': 'S',
  'week.6': 'S',
  'just-checkout': 'Just checkout',
  'min-nights': '{0}-night(s) minimum',
  'remove-dates': 'Clear dates',
}

const dict = {
  'es': es,
  'en': en,
}


export const trans = (lang: string, key: string, params?: (string | number)[]) => {
  let translation = dict[lang][key];
  if (params) {
    params.forEach((param, index) => {
      translation = translation.replace(`{${index}}`, param.toString());
    });
  }
  return translation;
}
