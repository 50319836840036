<rhbnb-filter-style-wrapper [alternateColor]="alternateColor">
  <rhbnb-tabs [showBorder]="disableOuting === false">
    <rhbnb-tab-item>

      <rhbnb-tab-header *ngIf="disableOuting === false" (clicked)="filterTabChange.emit(FilterTab.HOUSE)">
        <rhbnb-tab-header-item icon="home-city" [label]="'layout.tab_houses_label' | transloco"></rhbnb-tab-header-item>
      </rhbnb-tab-header>

      <rhbnb-tab-content>
        <rhbnb-house-filter [lang]="lang" [destinations$]="destinations$" [destination$]="destination$" [dateRange$]="dateRange$"
          [room$]="room$" [guest$]="guest$" [alternateColor]="alternateColor"
          (modelChange)="onFilterChange($event)"></rhbnb-house-filter>
      </rhbnb-tab-content>

    </rhbnb-tab-item>

    <rhbnb-tab-item>

      <rhbnb-tab-header *ngIf="disableOuting === false" (clicked)="filterTabChange.emit(FilterTab.OUTING)">
        <rhbnb-tab-header-item icon="pool" [label]="'layout.tab_outing_label' | transloco"></rhbnb-tab-header-item>
      </rhbnb-tab-header>

      <rhbnb-tab-content>
        <rhbnb-outing-filter [destinations$]="destinations$" [destination$]="outingDestination$"
          [dateRange$]="dateRange$" [guest$]="guest$" [loading$]="loading$" [alternateColor]="alternateColor"
          (modelChange)="onFilterChange($event)"></rhbnb-outing-filter>
      </rhbnb-tab-content>

    </rhbnb-tab-item>

  </rhbnb-tabs>

  <div ngClass.lt-md="btn-wrapper" style="min-height: 35px;" fxLayoutAlign="center stretch">

    <rhbnb-search-button-wrapper>
      <button mat-button id="search-btn" fxFlex="100"
        [ngClass]="alternateColor ? 'mat-stroked-button' : 'mat-flat-button'"
        [color]="!alternateColor ? 'primary' : undefined" [disabled]="loading$ | async" (click)="onSearchChange()">

        <rhbnb-loading-btn-body [loading$]="loading$"></rhbnb-loading-btn-body>

      </button>
    </rhbnb-search-button-wrapper>

  </div>
</rhbnb-filter-style-wrapper>
