import { Component, Input, DoCheck, OnDestroy, OnInit, Output, EventEmitter, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import { buildDateSafety, compareDate, dateRangeGenerator, formatDate, formatToDateParts, getDateLimits, getNextDate, isLock, isLockByMinNights, isOnlyOut } from './functions';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { WithUnsubscribe } from '@rhbnb-nx-ws/utils';

@Component({
  selector: 'rhbnb-range-picker',
  templateUrl: './range-picker.component.html',
  styleUrls: ['./range-picker.component.scss'],
})
export class RangePickerComponent extends WithUnsubscribe() implements OnInit, OnDestroy, DoCheck {
  @Input() minNights = 1;
  @Input() minDate;
  @Input() singleCalendar = false;
  @Input() selectRange = true;
  _lang: string;
  @Input() set lang(value: string) {
    this._lang = ['en', 'es'].includes(value) ? value : 'en';
  }
  get lang() {
    return this._lang;
  }
  @Input() set range(value: [string, string]) {
    const [from, to] = value;

    if (from === undefined || to === undefined) {
      this.firstSelected = undefined;
      this.lastSelected = undefined;

      return;
    }

    this.createMinNightsDates(from);

    if (!this.isValidRange(from, to)) {
      this.clearMinNightsDates();
      return;
    }

    this.clearMinNightsDates();

    this.firstSelected = from;
    this.lastSelected = to;
  }
  _singleSelected: string;
  @Input() set singleSelected(value: string) {
    if (!this.isValidSingle(value)) {
      return;
    }

    this._singleSelected = value;
  }
  get singleSelected() {
    return this._singleSelected;
  }
  _disabledDates: string[];
  @Input() set disabledDates(value: string[]) {
    const sorted = this.sortDates(value || []);
    this._disabledDates = sorted;
  }
  get disabledDates() {
    return this._disabledDates || [];
  }
  @Input() autoSize = false;
  @Input() autoChangeCalendar = false;
  @Input() loadDisabledDatesFn: (from: string, end: string) => Promise<string[]>;

  @Output() rangeChange = new EventEmitter<[string, string]>();
  @Output() singleSelectedChange = new EventEmitter<string>();
  @Output() viewDateChange = new EventEmitter<[string, string]>();

  currentMonth = new Date();
  nextMonth = new Date(this.currentMonth);
  firstSelected: string;
  lastSelected: string;
  hoverDate: string;
  nextDisabledDate: string;
  minNightsDates: string[] = [];
  dayElWidth;
  loading = false;

  destroy$$ = new Subject<void>();
  mouseOutCalendar$$ = new Subject<void>();
  mouseOutCalendar$ = this.mouseOutCalendar$$.asObservable();

  @ViewChild('calendarWrapper') calendarWrapperEl: ElementRef;

  constructor(
    private cdr: ChangeDetectorRef,
    private bpo: BreakpointObserver,
  ) {
    super();
  }

  ngDoCheck(): void {
    if (this.firstSelected && !this.lastSelected) {
      this.setNextDisabledDate();
      return;
    }

    this.nextDisabledDate = undefined;
  }

  ngOnDestroy(): void {
    this.destroy$$.next();
    this.destroy$$.complete();
  }

  ngOnInit(): void {
    this.mouseOutCalendar$$
      .pipe(
        takeUntil(this.destroy$$),
        debounceTime(10),
        tap(() => this.hoverDate = undefined),
        tap(() => this.cdr.detectChanges()),
      )
      .subscribe();

    if (this.autoSize) {
      this.bpo.observe(['(max-width: 699px)', '(min-width: 700px) and (max-width: 959px)', '(min-width: 960px) and (max-width: 1279px)', '(min-width: 1280px) and (max-width: 1919px)', '(min-width: 1920px)'])
        .pipe(
          takeUntil(this.unsubscribe$),
          debounceTime(500),
          tap(bps => this.autoAdjust(bps))
        )
        .subscribe();
    }

    // Set range on current month
    if (this.singleSelected || this.firstSelected) {
      const fromDate = buildDateSafety(this.singleSelected ? this.singleSelected : this.firstSelected);
      this.currentMonth = fromDate;
      this.nextMonth = new Date(this.currentMonth);
    }

    this.adjustNextMonth(0);
  }

  clearDates() {
    this.firstSelected = undefined;
    this.lastSelected = undefined;

    this.rangeChange.emit([this.firstSelected, this.lastSelected]);
  }

  autoAdjust(bps: BreakpointState) {
    const adjust = () => {
      const width = this.calendarWrapperEl.nativeElement.offsetWidth;

      // padding is 16px
      const padding = 16 * (this.singleCalendar ? 2 : 4);

      // 7 elements (every week day) twice (2 calendars)
      const elements = 7 * (this.singleCalendar ? 1 : 2);

      const borderPerEl = 1.5 * 2;
      const dayElWidth = (width - padding - (borderPerEl * elements)) / (elements);

      this.dayElWidth = Math.round(dayElWidth);

      this.cdr.detectChanges();
    }

    if (this.autoChangeCalendar) {
      if (bps.breakpoints['(max-width: 699px)']) {
        this.singleCalendar = true;
      } else {
        this.singleCalendar = false;
      }
    }

    setTimeout(() => adjust());
  }

  async onViewDateChange(from: string, to: string) {
    if (this.loadDisabledDatesFn && this.loadDisabledDatesFn instanceof Function) {
      this.loading = true;
      this.disabledDates = await this.loadDisabledDatesFn(from, to);
      this.loading = false;
    }

    this.viewDateChange.emit([from, to]);
  }

  previous(): void {
    const temp = new Date(this.currentMonth);
    temp.setMonth(this.currentMonth.getMonth() - 1);
    this.currentMonth = temp;

    this.adjustNextMonth(1);
  }

  next(): void {
    const temp = new Date(this.currentMonth);
    temp.setMonth(this.currentMonth.getMonth() + 1);
    this.currentMonth = temp;

    this.adjustNextMonth(0);
  }

  adjustNextMonth(sign = 0) {
    const temp = new Date(this.nextMonth);
    temp.setMonth(sign === 0 ? this.nextMonth.getMonth() + 1 : this.nextMonth.getMonth() - 1);

    this.nextMonth = temp;

    const [start] = getDateLimits(this.currentMonth);
    const [, end] = getDateLimits(this.nextMonth);

    this.onViewDateChange(start, end);
  }

  onMouseOut() {
    this.mouseOutCalendar$$.next();
  }

  onFirstSelectedChange(date: string) {
    if (date) {
      this.createMinNightsDates(date);
    } else {
      this.clearMinNightsDates();
    }
  }

  onLastSelectedChange(date: string) {
    if (date) {
      this.clearMinNightsDates();
      this.rangeChange.emit([this.firstSelected, this.lastSelected]);
    }
  }

  onSingleSelectedChange(date: string) {
    this.singleSelectedChange.emit(date);
  }

  private isValidRange(from: string, to: string) {
    const [y, m, d] = formatToDateParts(from);
    if (this.isOnlyOut(y, m, d)) {
      return false;
    }

    if (this.isLockByMinNights(to)) {
      return false;
    }

    for (const date of dateRangeGenerator(from, to)) {
      if (this.isLock(date)) {
        return false;
      }
    }

    return true;
  }

  private isValidSingle(value: string) {
    if (this.isLock(value)) {
      return false;
    }

    return true;
  }

  private isLockByMinNights(date: string) {
    return isLockByMinNights(date, this.minNightsDates);
  }

  private isLock(date: string) {
    return isLock(date, this.minDate, this.disabledDates, this.nextDisabledDate);
  }

  private isOnlyOut(year: number, month: number, day: number) {
    return isOnlyOut(year, month, day, this.disabledDates);
  }

  private sortDates(dates: string[]) {
    return [...new Set(dates)]
      .map(d => buildDateSafety(d))
      .sort((a, b) => a.getTime() - b.getTime())
      .map(d => formatDate(d));
  }

  private setNextDisabledDate() {
    this.nextDisabledDate = this.disabledDates.find(dd => compareDate(dd, this.firstSelected) === 1);
  }

  private createMinNightsDates(date: string) {
    const [y, m, d] = formatToDateParts(date);

    if (this.minNights > 1) {
      let itYear = y;
      let itMonth = m;
      let itDay = d;

      for (let i = 0; i < this.minNights - 1; i++) {
        const next = getNextDate(itDay, itMonth, itYear);
        this.minNightsDates = [...this.minNightsDates, next];

        const [y, m, d] = formatToDateParts(next);
        itYear = y;
        itMonth = m;
        itDay = d;
      }
    }
  }

  private clearMinNightsDates() {
    this.minNightsDates = [];
  }
}
