import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslocoModule } from '@ngneat/transloco';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RangePickerModule } from '../range-picker/range-picker.module';

import { TopDefaultFilterComponent } from './top-default-filter/top-default-filter.component';
import { AutoNumberedSelectModule } from '../auto-numbered-select/auto-numbered-select.module';
import { DaterangeModalModule } from '../daterange-modal/daterange-modal.module';
import { HouseFilterComponent } from './house-filter/house-filter.component';
import { SearchButtonWrapperComponent } from './search-button-wrapper/search-button-wrapper.component';
import { SimpleTabsModule } from '../simple-tabs/simple-tabs.module';
import { TabHeaderItemComponent } from './top-default-filter/tab-header-item.component';
import { OutingFilterComponent } from './outing-filter/outing-filter.component';
import { TopFilterPanelComponent } from './top-filter-panel/top-filter-panel.component';
import { TopPanelModule } from '../top-panel';
import { FilterStyleWrapperComponent } from './filter-style-wrapper/filter-style-wrapper.component';
import { LoadingBtnBodyComponent } from './search-button-wrapper/loading-btn-body.component';
import { SharedDirectivesModule } from '@rhbnb-nx-ws/shared-directives';
import { MomentModule } from 'ngx-moment';
import { SharedPipesModule } from '@rhbnb-nx-ws/shared-pipes';

const COMPONENTS = [
  TopDefaultFilterComponent,
  HouseFilterComponent,
  SearchButtonWrapperComponent,
  OutingFilterComponent,
  TabHeaderItemComponent,
  TopFilterPanelComponent,
  FilterStyleWrapperComponent,
  LoadingBtnBodyComponent
];

@NgModule({
  declarations: [
    ...COMPONENTS,
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    TranslocoModule,
    NgSelectModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    DaterangeModalModule,
    AutoNumberedSelectModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    SimpleTabsModule,
    TopPanelModule,
    RangePickerModule,
    SharedDirectivesModule,
    MomentModule,
    SharedPipesModule,
  ],
  exports: [
    ...COMPONENTS
  ]
})
export class TopFilterModule { }
