<div class="d-flex flex-column">
  <div #calendarWrapper class="d-flex flex-wrap align-items-stretch justify-content-around" (mouseleave)="onMouseOut()">
    <rhbnb-single-calendar [loading]="loading" [dayItemSize]="dayElWidth" [selectRange]="selectRange" [lang]="lang"
      [singleCalendar]="singleCalendar" [minNights]="minNights" [minNightsDates]="minNightsDates"
      [nextDisabledDate]="nextDisabledDate" [disabledDates]="disabledDates" [(firstSelected)]="firstSelected"
      [(singleSelected)]="singleSelected" [(lastSelected)]="lastSelected" [(hoverDate)]="hoverDate"
      (nextChange)="next()" (prevChange)="previous()" (firstSelectedChange)="onFirstSelectedChange($event)"
      (lastSelectedChange)="onLastSelectedChange($event)" (singleSelectedChange)="onSingleSelectedChange($event)"
      [date]="currentMonth | dateToString" [minDate]="minDate" place="LEFT"></rhbnb-single-calendar>

    <ng-container *ngIf="!singleCalendar">
      <rhbnb-single-calendar [loading]="loading" [dayItemSize]="dayElWidth" [selectRange]="selectRange" [lang]="lang"
        [minNightsDates]="minNightsDates" [minNights]="minNights" [nextDisabledDate]="nextDisabledDate"
        [disabledDates]="disabledDates" [(firstSelected)]="firstSelected" [(singleSelected)]="singleSelected"
        [(lastSelected)]="lastSelected" [(hoverDate)]="hoverDate" (nextChange)="next()" (prevChange)="previous()"
        (firstSelectedChange)="onFirstSelectedChange($event)" (lastSelectedChange)="onLastSelectedChange($event)"
        (singleSelectedChange)="onSingleSelectedChange($event)" [date]="nextMonth | dateToString" [minDate]="minDate"
        place="RIGHT"></rhbnb-single-calendar>
    </ng-container>
  </div>

  <div [style.visibility]="(firstSelected && lastSelected) ? 'visible' : 'hidden'" class="pb-1 pe-4 flex-grow-1 d-flex justify-content-end">
    <button mat-button (click)="clearDates()">{{ 'remove-dates' | trans:lang }}</button>
  </div>
</div>
