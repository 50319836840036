import { Action, createReducer, on } from '@ngrx/store';
import { Destination, FilterTab } from '@rhbnb-nx-ws/domain';
import * as moment from 'moment';

import * as Actions from './actions';

export const featureModuleKey = 'layout';

export interface State {
  destinations: Destination[];
  loadingDestinations: boolean;
  filter: Filter;
  activeUIFilter: UIFilter;
  disableBarPlaceholder: boolean;
  filterLoading: boolean;
  loadingLogout: boolean;
}

export interface Filter {
  selectedTab: FilterTab;
  destination: number | string;
  selectedProvince: number;
  outingDestination: number | string;
  outingSelectedProvince: number;
  dateRange: DateRange;
  roomCount: number;
  guestCount: number;
  nights: number;
  facilities: (string | number)[];
  loaded: boolean;
  within: number[][];
}

export type UIFilter = 'none' | 'all' | 'houses' | 'outing';

export interface DateRange {
  chosenLabel?: string;
  startDate: string;
  endDate: string;
}

export const initialState: State = {
  destinations: [],
  loadingDestinations: false,
  activeUIFilter: 'none',
  disableBarPlaceholder: false,
  filter: {
    selectedTab: FilterTab.HOUSE,
    destination: undefined,
    selectedProvince: undefined,
    outingDestination: undefined,
    outingSelectedProvince: undefined,
    dateRange: {
      startDate: undefined,
      endDate: undefined,
      chosenLabel: undefined
    },
    roomCount: undefined,
    guestCount: undefined,
    facilities: [],
    nights: 0,
    loaded: false,
    within: undefined,
  },
  filterLoading: false,
  loadingLogout: false
};

const coreReducer = createReducer(
  initialState,

  on(Actions.setDestinations, (state, payload) => ({
    ...state,
    destinations: payload.destinations
  })),

  on(Actions.setDestinationsLoading, ((state, payload) => ({
    ...state,
    loadingDestinations: payload.loading
  }))),

  on(Actions.setFilter, ((state, payload) => {
    return ({
      ...state,
      filter: payload
    })
  })),

  on(Actions.setFilterLoaded, ((state, payload) => ({
    ...state,
    filter: {
      ...state.filter,
      loaded: payload.loaded
    }
  }))),

  on(Actions.setFilterTab, ((state, payload) => ({
    ...state,
    filter: {
      ...state.filter,
      selectedTab: payload.tab
    }
  }))),

  on(Actions.setActiveUIFilter, ((state, payload) => ({
    ...state,
    activeUIFilter: payload.filter
  }))),

  on(Actions.setDisableBarPlaceholder, ((state, payload) => ({
    ...state,
    disableBarPlaceholder: payload.disable
  }))),

  on(Actions.setFilterDestination, ((state, payload) => ({
    ...state,
    filter: {
      ...state.filter,
      destination: payload.destination
    }
  }))),

  on(Actions.setFilterSelectedProvince, ((state, payload) => ({
    ...state,
    filter: {
      ...state.filter,
      selectedProvince: +payload.province
    }
  }))),

  on(Actions.setFilterOutingDestination, ((state, payload) => ({
    ...state,
    filter: {
      ...state.filter,
      outingDestination: payload.destination
    }
  }))),

  on(Actions.setFilterOutingSelectedProvince, ((state, payload) => ({
    ...state,
    filter: {
      ...state.filter,
      outingSelectedProvince: +payload.province
    }
  }))),

  on(Actions.setFilterDateRange, ((state, payload) => {
    return ({
      ...state,
      filter: {
        ...state.filter,
        dateRange: {
          chosenLabel: payload.chosenLabel,
          startDate: payload.startDate?.format('YYYY-MM-DD'),
          endDate: payload.endDate?.format('YYYY-MM-DD')
        }
      }
    })
  })),

  on(Actions.setFilterRoomCount, ((state, payload) => ({
    ...state,
    filter: {
      ...state.filter,
      roomCount: payload.number
    }
  }))),

  on(Actions.setFilterGuestCount, ((state, payload) => ({
    ...state,
    filter: {
      ...state.filter,
      guestCount: payload.number
    }
  }))),

  on(Actions.setFilterFacilities, ((state, payload) => ({
    ...state,
    filter: {
      ...state.filter,
      facilities: payload.facilities
    }
  }))),

  on(Actions.setFilterLoading, ((state, payload) => ({
    ...state,
    filterLoading: payload.loading
  }))),

  on(Actions.setFilterWithin, ((state, payload) => ({
    ...state,
    filter: {
      ...state.filter,
      within: payload.within,
    }
  }))),

  on(Actions.setLoadingLogout, ((state, payload) => ({
    ...state,
    loadingLogout: payload.loading
  }))),

  on(Actions.computeFilterNights, ((state) => {
    const start = state.filter?.dateRange?.startDate;
    const end = state.filter?.dateRange?.endDate;
    let nights = 0;

    if (start && end) {
      nights = moment(end).diff(moment(start), 'day');
    }

    return {
      ...state,
      filter: {
        ...state.filter,
        nights
      }
    };
  })),
);

export function reducer(state: State | undefined, action: Action): any {
  return coreReducer(state, action);
}
