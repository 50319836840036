import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  IntersectionObserverDirective,
  LazyLoadDirective,
  NumbersOnly,
  MatSelectInfiniteScrollDirective,
  ScrollDirectionDirective,
  SubmitIfValidDirective,
  IsHostmanagerDirective,
  IsOnlyClientDirective,
  IsOnlyHostDirective,
  PanelStatusDirective,
  PaymentStyleStatusDirective,
  CopyClipboardDirective,
  HasRoleDirective,
  LazySizesDirective,
  CountUpDirective,
  ClickOutsideDirective,
} from './directives';

const DIRECTIVES = [
  IntersectionObserverDirective,
  LazyLoadDirective,
  MatSelectInfiniteScrollDirective,
  NumbersOnly,
  ScrollDirectionDirective,
  SubmitIfValidDirective,
  IsHostmanagerDirective,
  IsOnlyClientDirective,
  IsOnlyHostDirective,
  PanelStatusDirective,
  PaymentStyleStatusDirective,
  CopyClipboardDirective,
  HasRoleDirective,
  LazySizesDirective,
  CountUpDirective,
  ClickOutsideDirective,
];

@NgModule({
  imports: [CommonModule],
  declarations: [
    ...DIRECTIVES,
  ],
  exports: [
    ...DIRECTIVES
  ]
})
export class SharedDirectivesModule {}
