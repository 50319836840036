<div class="calendar">
  <div class="header">
    <button *ngIf="singleCalendar || place === 'LEFT'" (click)="onPrev()" class="control-btn">
      <span data-button-content="true">
        <svg viewBox="0 0 18 18" role="presentation" aria-hidden="true" focusable="false"
          style="height: 12px; width: 12px; display: block; fill: currentcolor;">
          <path d="m13.7 16.29a1 1 0 1 1 -1.42 1.41l-8-8a1 1 0 0 1 0-1.41l8-8a1 1 0 1 1 1.42 1.41l-7.29 7.29z"
            fill-rule="evenodd"></path>
        </svg></span>
    </button>
    <div class="monthLabel">
      <span>{{ ('month.'+myCalendarMonth) | trans:lang }} {{ myCalendarYear }}</span>
    </div>
    <button *ngIf="singleCalendar || place === 'RIGHT'" (click)="onNext()" class="control-btn">
      <span data-button-content="true"><svg viewBox="0 0 18 18" role="presentation" aria-hidden="true" focusable="false"
          style="height: 12px; width: 12px; display: block; fill: currentcolor;">
          <path d="m4.29 1.71a1 1 0 1 1 1.42-1.41l8 8a1 1 0 0 1 0 1.41l-8 8a1 1 0 1 1 -1.42-1.41l7.29-7.29z"
            fill-rule="evenodd"></path>
        </svg></span>
    </button>
  </div>
  <div class="days-of-week">
    <div *ngFor="let day of daysOfWeek; trackBy: trackByFn">{{ ('week.'+day) | trans:lang }}</div>
  </div>
  <div class="weeks" *ngFor="let week of weeks; trackBy: trackByFn">
    <div #elDay class="day" [ngClass]="
          {
            'valid': !!day
               && (
                !minDate
                || (
                  (day?.day
                    | buildDateString:myCalendarYear:(myCalendarMonth+1)
                    | compareDate:minDate) === 1)
                ),
            'empty': !day,
            'only-out': selectRange && (!firstSelected || firstSelected && lastSelected) && day?.day | getNextDate:myCalendarYear:(myCalendarMonth+1) | isInArray:disabledDates,
            'lock': (
              minDate
              && (
                  day?.day | buildDateString:myCalendarYear:(myCalendarMonth+1)
                  | isDateLessThanOrEqual:minDate
                )
              )
              ||
              (
                day?.day
                | buildDateString:myCalendarYear:(myCalendarMonth+1)
                | isInArray:disabledDates
              )
              ||
              (
                day?.day
                | buildDateString:myCalendarYear:(myCalendarMonth+1)
                | isInArray:minNightsDates
              )
              ||
              (
                firstSelected
                &&
                nextDisabledDate
                &&
                day?.day
                  | buildDateString:myCalendarYear:(myCalendarMonth+1)
                  | isDateGreaterThanOrEqual:nextDisabledDate
              )
              ,
            'selected-first': selectRange &&
              day?.day
                | buildDateString:myCalendarYear:(myCalendarMonth+1)
                | isSelectedDate:firstSelected,
            'selected-single':
                !selectRange &&
                day?.day
                | buildDateString:myCalendarYear:(myCalendarMonth+1)
                | isSelectedDate:singleSelected,
            'selected-last':
                selectRange && (
                day?.day
                | buildDateString:myCalendarYear:(myCalendarMonth+1)
                | isSelectedDate:hoverDate
              )
              ||
              (
                day?.day
                | buildDateString:myCalendarYear:(myCalendarMonth+1)
                | isSelectedDate:lastSelected
              ),
            'range':
              selectRange &&
              lastSelected
                ? (
                  day?.day
                    | buildDateString:myCalendarYear:(myCalendarMonth+1)
                    | isInRange:firstSelected:lastSelected)
                : (
                  day?.day
                    | buildDateString:myCalendarYear:(myCalendarMonth+1)
                    | isInRange:firstSelected:hoverDate
                  ),
            'min-night-alert': day?.day
              | buildDateString:myCalendarYear:(myCalendarMonth+1)
              | isInArray:minNightsDates
          }" [attr.data-date]="day?.day | buildDateString:myCalendarYear:(myCalendarMonth+1)"
      (click)="onSelect(day?.day, elDay)" (mouseover)="onDateHover(day?.day)" (mouseleave)="removeTooltip(elDay)" *ngFor="let day of week">

      <div class="day-item" [ngStyle]="{'width.px': dayItemSize, 'height.px': dayItemSize}">
        {{ day?.day || '' }}
      </div>

    </div>
  </div>
</div>
