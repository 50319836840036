import { Pipe, PipeTransform } from '@angular/core';
import { compareDate } from './functions';

@Pipe({
  name: 'isDateGreaterThanOrEqual'
})

export class IsDateGreaterThanOrEqualPipe implements PipeTransform {
  transform(d1: string, d2: string): any {
    return compareDate(d1, d2) === 1 || compareDate(d1, d2) === 0;
  }
}
