import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { DateRangeModel, Destination, FilterTab } from '@rhbnb-nx-ws/domain';

import { FilterChangesModel, FilterDataChange } from '../filter-changes.model';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'rhbnb-top-default-filter',
  templateUrl: './top-default-filter.component.html',
  styleUrls: ['./top-default-filter.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopDefaultFilterComponent implements OnInit {

  FilterTab = FilterTab;

  @Input() destinations$: Observable<Destination[]>;
  @Input() destination$: Observable<number | string>;
  @Input() outingDestination$: Observable<number | string>;
  @Input() dateRange$: Observable<DateRangeModel>;
  @Input() room$: Observable<number>;
  @Input() guest$: Observable<number>;
  @Input() loading$: Observable<boolean>;
  @Input() alternateColor = false;
  @Input() disableOuting = false;
  @Input() lang: string;

  @Output() filterTabChange = new EventEmitter<FilterTab>();
  @Output() modelChange = new EventEmitter<FilterDataChange>();

  constructor() {
  }

  ngOnInit(): void {
    this.loading$ = this.loading$.pipe(delay(0));
  }

  onFilterChange(e: FilterDataChange) {
    this.modelChange.emit(e);
  }

  onSearchChange(): void {
    this.onFilterChange({type: FilterChangesModel.SEARCH});
  }
}
