<div class="list-wrapper"
     fxLayout="row wrap"
     fxLayout.xxs="column"
     fxLayoutAlign="start stretch"
     fxLayoutAlign.xxs="center stretch"
     fxLayoutGap="15px grid">

  <ng-container *ngIf="tiny; else noTiny">
    <rhbnb-house-rooms-item-tiny
      fxFlex="100"
      *ngFor="let room of rooms; trackBy: trackByFn"

      [fxFlex.gt-xs]="_gtXsValue"
      [fxFlex.gt-sm]="_gtSmValue"
      fxLayout="row wrap"
      [room]="room"
      [commission]="commission"
      [cupRate]="cupRate"
      [selected]="room.selected"
      [unavailable]="room.available === false"
      [interactive]="selectable && room.available"
      [showCalendar]="showCalendar()"
      [calendarValue]="calendarValue"
      [currencyTable$]="currencyTable$"
      [currentCurrency$]="currentCurrency$"
      [translateKey]="translateKey"

      (selectedChange)="onSelectedChange($event)"
      (calendarChange)="onRoomsCalendarChange(room?.id, $event)">
    </rhbnb-house-rooms-item-tiny>
  </ng-container>

  <ng-template #noTiny>
    <rhbnb-house-rooms-item
      fxFlex="100"
      *ngFor="let room of rooms; trackBy: trackByFn"

      [fxFlex.gt-xs]="_gtXsValue"
      [fxFlex.gt-sm]="_gtSmValue"
      fxLayout="row wrap"
      [room]="room"
      [commission]="commission"
      [cupRate]="cupRate"
      [selected]="room.selected"
      [unavailable]="room.available === false"
      [interactive]="selectable && room.available"
      [showCalendar]="showCalendar()"
      [calendarValue]="calendarValue"
      [currencyTable$]="currencyTable$"
      [currentCurrency$]="currentCurrency$"
      [translateKey]="translateKey"
      [lang]="lang"
      [minNights]="minNights"

      (selectedChange)="onSelectedChange($event)"
      (calendarChange)="onRoomsCalendarChange(room?.id, $event)">
    </rhbnb-house-rooms-item>
  </ng-template>
</div>
