import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from './functions';

@Pipe({
  name: 'dateToString'
})

export class DateToStringPipe implements PipeTransform {
  transform(date: Date, ...args: any[]): any {
    return formatDate(date);
  }
}
