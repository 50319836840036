import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  AppCommissionPipe,
  CurrencyConvertPipe,
  ElementImagePipe,
  ElementImagePipeService,
  QualityFilterPipe,
  RoundPipe,
  CurrencyLabelPipe,
  ToDateStringWithoutOffsetPipe,
  ToDecimalPipe,
  PriceLabelPipe,
  ComputePricePipe,
  BookingStatusLabelPipe,
  BookingClientImagePipe,
  BookingCountNightsPipe,
  AddNegativeSignPipe,
  UrlHostnamePipe,
  FilterImagePipe,
  FilterImagePipeService,
  TruncatePipe,
  BypassHtmlPipe,
  WhatsAppNumberPipe,
  FormatPhoneNumberPipe,
  CompactNumberPipe,
  CompactNumberService,
  IsMomentValidPipe,
} from './pipes';

const PIPES = [
  ToDateStringWithoutOffsetPipe,
  QualityFilterPipe,
  ElementImagePipe,
  ToDecimalPipe,
  PriceLabelPipe,
  ComputePricePipe,
  AppCommissionPipe,
  CurrencyConvertPipe,
  RoundPipe,
  CurrencyLabelPipe,
  BookingStatusLabelPipe,
  BookingClientImagePipe,
  BookingCountNightsPipe,
  AddNegativeSignPipe,
  UrlHostnamePipe,
  FilterImagePipe,
  TruncatePipe,
  BypassHtmlPipe,
  WhatsAppNumberPipe,
  FormatPhoneNumberPipe,
  CompactNumberPipe,
  IsMomentValidPipe,
];

@NgModule({
  imports: [CommonModule],
  declarations: [
    ...PIPES,
  ],
  exports: [
    ...PIPES,
  ],
  providers: [
    FilterImagePipeService,
    ElementImagePipeService,
    CompactNumberService,
  ]
})
export class SharedPipesModule {}
