import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isInArray'
})

export class IsInArrayPipe implements PipeTransform {
  transform(date: string, dates: string[]): any {
    if (!dates) {
      return false;
    }

    return dates.includes(date);
  }
}
