import { Pipe, PipeTransform } from '@angular/core';
import { datePartsToFormat } from './functions';

@Pipe({
  name: 'buildDateString'
})

export class BuildDateStringPipe implements PipeTransform {
  transform(day?: number, year?: number, month?: number): any {
    return datePartsToFormat(year, month, day);
  }
}
