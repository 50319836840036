import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isSelectedDate'
})

export class IsSelectedDatePipe implements PipeTransform {
  transform(date: string, selected: string): any {
    return date === selected;
  }
}
