import { ChangeDetectionStrategy, Component } from '@angular/core';
import { showing } from '@rhbnb-nx-ws/utils';
import { transition, trigger, useAnimation } from '@angular/animations';
import { fadeIn, fadeOut } from 'ng-animate';
import { HouseRoomsItemBaseComponent } from './house-rooms-item-base.component';

@Component({
  selector: 'rhbnb-house-rooms-item',
  template: `
      <div fxFlex="1 1 100"
           fxLayout="column"
           fxLayoutAlign="start stretch"
           class="room-item"
           (click)="onSelectedChange()">

          <div class="image-container">

              <div [@labelShowing] *ngIf="unavailable" tabindex="-1" class="label unavailable">
                <span fxLayout="row wrap" fxLayoutAlign="center center">
                    <mat-icon svgIcon="emoticon-sad-outline"></mat-icon>
                    <span>{{ 'houses.reservation_room_unavailable_label' | transloco }}</span>
                </span>
              </div>

              <div [@labelShowing] *ngIf="selected" tabindex="-1" class="label selected">
                <span fxLayout="row wrap" fxLayoutAlign="center center">
                    <mat-icon svgIcon="thumb-up-outline"></mat-icon>
                    <span>{{ 'houses.reservation_room_selected_label' | transloco }}</span>
                </span>
              </div>

              <div *ngIf="price" tabindex="-1" class="price-holder">
                  <span>{{ $any(price | appCommission: commission |
                          toDecimal |
                          currencyConvert: {cupRate: cupRate, currencyTable$: currencyTable$, currentCurrency$: currentCurrency$, ignoreCurrency: ['CUP']} | async |
                          round: 2 |
                          currency:'':'') |
                          currencyLabel: {currentCurrency$: currentCurrency$} | async
                      }}</span>
              </div>

              <picture rhbnb-lazy-sizes-picture [src]="coverImage" [errorImage]="emptyImage" [alt]="room?.name"></picture>
          </div>

          <div fxLayout="column"
               fxLayoutAlign="start stretch"
               fxFlex="100"
               class="content-container">

              <div class="icon mb-5">
                  <mat-icon class="mr-5"
                            *ngFor="let bed of room?.beds"
                            [svgIcon]="getBedTypeIcon(bed.kind)">
                  </mat-icon>

                  <mat-icon *ngIf="room.hasBath"
                            [style.float]="'right'"
                            [style.transform]="'scale(1.5)'"
                            class="mr-5"
                            svgIcon="bath">
                  </mat-icon>
              </div>

              <div class="title mb-10">
                  {{ room.name }}
              </div>

              <div class="mt-5">
                  {{ itemCapacityKey | transloco: {count: room.guests} }}
              </div>

              <mat-divider class="my-10"></mat-divider>

              <div class="bed-info">
                  <div class="bed-item" *ngFor="let bed of room?.beds">
                      {{ bed.count}} {{ getBedTypeName(bed.kind) | transloco: {count: bed.count} }}
                  </div>

                  <div class="bed-item" *ngIf="room.hasBath">
                      {{ privateBathKey | transloco }}
                  </div>
              </div>

              <ng-container *ngIf="showCalendar">
                  <mat-divider class="my-10"></mat-divider>

                  <div class="mb-10">{{ 'houses.house_room_item_availability' | transloco }}</div>

                  <rhbnb-date-picker-inline
                    (viewDateChange)="onLimitsChange($event)"
                    (rangeChange)="inlineRangeDate($event)"
                    [lang]="lang"
                    [minNights]="minNights"
                    [disabledDates]="disabledDates"
                    [range]="[calendarValue?.range.startDate, calendarValue?.range.endDate]"
                    [autoSize]="true"
                    [singleCalendar]="true"></rhbnb-date-picker-inline>

              </ng-container>

          </div>
      </div>
  `,
  styles: [
    `
          .room-item {
              box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
              border-radius: 12px !important;
              width: 100% !important;
          }

          .room-item .image-container {
              position: relative;
              border-top-right-radius: 12px !important;
              border-top-left-radius: 12px !important;
          }

          .bg-loading-color {
              background-color: #dddddd;
          }

          .room-item .image-container .label {
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              text-align: center;
              outline: none;
              z-index: 1;
          }

          .room-item .image-container .label > span {
              padding: 5px 8px;
              border-radius: 8px;
              color: #CB1515;
              font-size: large;
              background-color: white;
          }

          .room-item .image-container .label.unavailable > span {
              color: #CB1515;
          }

          .room-item .image-container .label.selected > span {
              color: #4eb96f;
          }

          .room-item .image-container .label > span > span {
              font-weight: 500;
              margin-left: 5px;
          }

          .room-item .image-container .price-holder {
              position: absolute;
              background-color: #3498db;
              color: white;
              right: 10px;
              bottom: 15px;
              outline: none;
              z-index: 1;
              padding: 2px 5px;
          }



          .room-item .image-container .img:before {
              content: "";
              float: left;
              padding-top: 65%;
          }

          .room-item .content-container {
              padding: 24px !important;
          }

          .room-item .title {
              font-weight: 500;
              font-size: 20px;
          }

          .room-item .bed-info {
              height: 100%;
          }

          .room-item div.bed-item:not(:last-child) {
              margin-bottom: 5px;
          }

          :host(::ng-deep .room-item .image-container picture > img) {
              border-top-right-radius: 12px !important;
              border-top-left-radius: 12px !important;
              object-fit: cover;
              width: 100%;
              max-height: 182px;
          }

          .room-item .image-container .img {
              object-fit: cover;
              width: 100%;
          }

    `
  ],
  animations: [
    trigger('labelShowing', [
      transition(':enter', useAnimation(fadeIn, {
        params: { timing: .1 }
      })),
      transition(':leave', useAnimation(fadeOut, {
        params: { timing: .1 }
      }))
    ]),

    showing()
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HouseRoomsItemComponent extends HouseRoomsItemBaseComponent {
}
